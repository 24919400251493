<template>
  <div class="hostingPlatforms">
    <div class="header">
      <div :class="['left', {'active': isCollapse}]">
        <transition name="el-zoom-in-center">
            <div v-if="isCollapse" class="logos">
              <img @click="menu"   src="../../assets/images/hostingPLatform/index/favicon.png" alt="">
            </div>

            <img v-else class="logo" src="../../assets/images/hostingPLatform/index/logo.png" alt="">
        </transition>
            <div v-if="!isCollapse" class="menu">
              <img  @click="menu" src="../../assets/images/hostingPLatform/index/menu.png" alt="">
            </div>

      </div>
      <div :class="['right', {'active': isCollapse}]">
        <div style="display: flex">
          <img class="home_logo" src="../../assets/images/hostingPLatform/index/home_logo.png" alt="">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">蓝灯鱼网站</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/hostingPlatforms/home' }">中小微企业智产托管平台</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/hostingPlatforms/home' }">{{getName()}}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <ul style="display: flex;align-items: center">
          <li style="position: relative" v-if="me">
              <el-popover
                placement="bottom"
                popper-class="hostingPlatformpopper"
                width="360"
                :offset="10"
                v-model="visible"
                trigger="manual">
                <div>
                  <div class="myTitle">
                    <span>我的</span>
                    <img @click="close" src="../../assets/images/hostingPLatform/index/close.png" alt="">
                  </div>
                  <div class="membershipManagement">
                    <div>会员管理</div>
                    <div style="cursor: pointer" @click="goPays">续费</div>
                  </div>
                  <div class="service">
                    <el-carousel indicator-position="none"  v-if="serviceList.length > 0" height="70px">
                      <el-carousel-item :key="index" v-for="(item,index) in serviceList">
                        <div class="task-center">
                          <span class="date">到期时间：{{item.endTime}}</span>
                          <img v-if="item.serviceName.indexOf('白银') != -1" @click="goPay"
                               src="../../assets/images/hostingPLatform/index/VIP_A.png" mode=""/>
                          <img v-if="item.serviceName.indexOf('黄金') != -1" @click="goPay"
                               src="../../assets/images/hostingPLatform/index/VIP_B.png" mode=""/>
                          <img v-if="item.serviceName.indexOf('铂金') != -1" @click="goPay"
                               src="../../assets/images/hostingPLatform/index/VIP_C.png" mode=""/>
                          <img v-if="item.serviceName.indexOf('黑钻') != -1" @click="goPay"
                               src="../../assets/images/hostingPLatform/index/VIP_D.png" mode=""/>
                        </div>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                  <div class="applicantManagement">
                    <div class="applicantManagement_title">
                      <div>托管申请人管理</div>
                      <div @click="addApplicant">
                        <img src="../../assets/images/hostingPLatform/index/addicon.png" alt="">
                        新增
                      </div>
                    </div>
                    <ul class="applicantManagement_content">
                      <li  v-for="(item,index) in applicant" :key="index">
                        <span>{{item.text}}</span>
                        <img @click="goApplicant(item)" src="../../assets/images/hostingPLatform/index/Frame.png" alt="">
                      </li>
                    </ul>
                  </div>
                  <div class="contactUs">
                    <ul>
                      <li>会员套餐定制？</li>
                      <li>数据更新有疑问？</li>
                      <li>代缴流程有疑问？</li>
                    </ul>
                    <img src="../../assets/images/hostingPLatform/index/contactUs.png" alt="">
                  </div>
                </div>
<!--                <el-dropdown  @command="handleCommand"  >-->
                <span @click="visible = !visible" slot="reference" :style="{fontSize: '16px',display: 'flex',alignItems: 'center'}" :class="['el-dropdown-link']">
                  <img v-if="me.vipType && me.vipType != 'COMMON'" style="margin-right: 10px" :src="require(`../../assets/images/header/${me.vipType}.png`)" alt="">   {{getIphone(me.phone)}}
<!--                  <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--                      <el-dropdown-menu slot="dropdown">-->
<!--                        <el-dropdown-item  command="a">我的</el-dropdown-item>-->
<!--                      </el-dropdown-menu>-->
                </span>
<!--              </el-dropdown>-->
              </el-popover>
          </li>
          <li v-if="me" class="notice" @mouseenter="noticeBool = true" @mouseleave="mouseleaveEvent">
            <el-badge :is-dot="dotBool">
              <i :style="{color: active ?  '#9ca3af' : '#9ca3af'} " class="el-icon-bell"></i>
            </el-badge>
            <div class="notice_po">
              <ul class="notice_con" :class="{noDefault:noticeList== undefined || noticeList.length == 0}"
                  v-show="noticeBool">
                <li v-if="noticeList== undefined || noticeList.length == 0" class="no_notice">{{$t(`headers.noData`)}}
                </li>
                <li v-else v-for="(item,index) in noticeList" :key="index">
                  <p>
                    <span>
                      <b>{{item.messageTitle}}</b>
                      {{item.messageContent}}
                    </span>
                    <i>{{item.createTime}}</i>
                  </p>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <el-container style="min-height: 100%">
      <el-aside >
        <el-menu
          router
          :default-active="$route.path"
          :collapse="isCollapse"
          class="el-menu-vertical-demo"
          background-color="transparent"
          text-color="#909EB0"
          active-text-color="#2D3748">
          <el-menu-item index="/hostingPlatforms/home">
            <img style="margin-right: 7px;margin-left: 5px;width: 37px" v-if="$route.path == '/hostingPlatforms/home'" src="../../assets/images/hostingPLatform/index/Group 82.png" alt="">
            <img style="margin-right: 7px;margin-left: 5px;width: 37px" v-else src="../../assets/images/hostingPLatform/index/home.png" alt="">
            <span slot="title">首页</span>
          </el-menu-item>
          <el-submenu index="/hostingPlatforms">
            <template slot="title">
              <img style="margin-right: 7px" v-if="$route.path == '/hostingPlatforms/annualFee' || $route.path == '/hostingPlatforms/myAnnualFee' || $route.path == '/hostingPlatforms/annualFee/bs'" src="../../assets/images/hostingPLatform/index/Group 40.png" alt="">
              <img style="margin-right: 7px" v-else src="../../assets/images/hostingPLatform/index/patent.png" alt="">
              <span>年费管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/hostingPlatforms/annualFee">
                <div style="display: flex">
                  <div style="width: 31px;height: auto; "/>
                  年费监控
                </div>
              </el-menu-item>
              <el-menu-item index="/hostingPlatforms/myAnnualFee">
                <div style="display: flex">
                  <div style="width: 31px;height: auto; "/>
                  年费订单
                </div>
              </el-menu-item>
<!--              <el-menu-item index="/hostingPlatforms/annualFee/bs">BS流程</el-menu-item>-->
            </el-menu-item-group>
          </el-submenu>
          <el-submenu >
            <template slot="title">
              <img style="margin-right: 7px" v-if="$route.path == '/hostingPlatforms/trademark' || $route.path == '/hostingPlatforms/myRenewal' || $route.path == '/hostingPlatforms/trademark/bs'" src="../../assets/images/hostingPLatform/index/Group 195.png" alt="">
              <img style="margin-right: 7px" v-else src="../../assets/images/hostingPLatform/index/trem.png" alt="">
              <span slot="title">商标管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/hostingPlatforms/trademark">
                <div style="display: flex">
                  <div style="width: 33px;height: auto; "/>
                  商标监控
                </div>
              </el-menu-item>
              <el-menu-item index="/hostingPlatforms/myRenewal">
                <div style="display: flex">
                  <div style="width: 33px;height: auto; "/>
                  商标订单
                </div>
              </el-menu-item>
<!--              <el-menu-item index="/hostingPlatforms/trademark/bs">BS流程</el-menu-item>-->
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="/hostingPlatforms/softness">
            <img style="margin-right: 7px" v-if="$route.path == '/hostingPlatforms/softness'" src="../../assets/images/hostingPLatform/index/Group 86.png" alt="">
            <img style="margin-right: 7px" v-else src="../../assets/images/hostingPLatform/index/ruan.png" alt="">
            <span slot="title">软著管理</span>
          </el-menu-item>
          <el-menu-item index="/hostingPlatforms/qualifications">
            <img style="margin-right: 7px" v-if="$route.path == '/hostingPlatforms/qualifications'" src="../../assets/images/hostingPLatform/index/Group 186.png" alt="">
            <img style="margin-right: 7px" v-else src="../../assets/images/hostingPLatform/index/zizhi.png" alt="">
            <span slot="title">资质管理</span>
          </el-menu-item>
          <el-menu-item index="/hostingPlatforms/institution">
            <img style="margin-right: 7px" v-if="$route.path == '/hostingPlatforms/institution'" src="../../assets/images/hostingPLatform/index/Group 188.png" alt="">
            <img style="margin-right: 7px" v-else src="../../assets/images/hostingPLatform/index/jigou.png" alt="">
            <span slot="title">寻找机构</span>
          </el-menu-item>
        </el-menu>
        <img @click="goPays" class="content" v-if="isCollapse" src="../../assets/images/hostingPLatform/index/bottom.png" alt="">
        <img @click="goPays" class="content" v-else src="../../assets/images/hostingPLatform/index/Group 56.png" alt="">
      </el-aside>
      <el-container>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
    <el-dialog width="800px" custom-class="maturityDialogTable" :visible.sync="maturityDialogTableVisible">
      <div class="summaryData">
        <div class="summaryData_title">
          <img src="../../assets/images/hostingPLatform/prompt.png" alt="">
          <p>以下数据托管已到期，请您确定是否继续托管：</p>
        </div>
        <div class="summaryItem" >
          <el-select style="margin: 20px 0px" @change="changeValue" v-model="value" placeholder="请选择">
            <el-option
              v-for="(item,index) in summaryData"
              :key="index"
              :label="item.applicantName"
              :value="index">
            </el-option>
          </el-select>
          <div class="item" v-if="item.patentExpireList && item.patentExpireList.length > 0">
            <div style="text-align: left">
              <span class="item_title">专利 &nbsp;&nbsp;{{item.patentExpireList && item.patentExpireList.length ? item.patentExpireList && item.patentExpireList.length : 0}}</span>
            </div>
            <!--            <i @click="open(1,index,0 , item.applicantName + 0)" :class="[index1 ? 'el-icon-arrow-up' : 'el-icon-arrow-down','icon']"></i>-->
            <el-table
              :header-cell-style="{background: '#D3DBF8'}"
              class="table"
              style="width: 660px"
              height="220"
              :row-key="getRowKeys"
              :key="item.applicantName + 0"
              :ref="item.applicantName + 0"
              :data="item.patentExpireList && item.patentExpireList"
              tooltip-effect="dark"
              @selection-change="(val) => handleSelectionChange(val,0,0)">
              <el-table-column
                align="center"
                :reserve-selection="true"
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column
                label="专利名称"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{ scope.row.patentName }}</template>
              </el-table-column>
              <el-table-column
                prop="patentApplicationNo"
                label="申请号"
              >
              </el-table-column>
              <el-table-column
                prop="address"
                label="状态"
                show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.patentLawStatus ? '有效' : '失效' }}</template>
              </el-table-column>
            </el-table>
          </div>

          <div class="item" v-if="item.trademarkRenewalExpireList && item.trademarkRenewalExpireList.length > 0">
            <div style="text-align: left">
              <span class="item_title">商标&nbsp;&nbsp;{{item.trademarkRenewalExpireList && item.trademarkRenewalExpireList.length ? item.trademarkRenewalExpireList && item.trademarkRenewalExpireList.length : 0}}</span>
            </div>
            <!--            <i @click="open(1,index,1, item.applicantName + 1)" :class="[index2 ? 'el-icon-arrow-up' : 'el-icon-arrow-down','icon']"></i>-->
            <el-table
              :header-cell-style="{background: '#D3DBF8'}"
              class="table"
              style="width: 660px"
              height="220"
              :row-key="getRowKeys"
              :ref="item.applicantName + 1"
              :data="item.trademarkRenewalExpireList && item.trademarkRenewalExpireList"
              tooltip-effect="dark"
              @selection-change="(val) => handleSelectionChange(val,0,1)">
              <el-table-column
                :reserve-selection="true"
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column
                label="商标名称"
              >
                <template slot-scope="scope">{{ scope.row.trademarkName }}</template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="商标图案"
              >
                <template slot-scope="scope">
                  <img style="width: 60px" :src="queryDataUrl(scope.row.trademarkImageUrl)" alt="">
                </template>
              </el-table-column>
              <el-table-column
                prop="trademarkApplyNo"
                label="申请号"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                prop="trademarkStatus"
                label="商标状态"
                show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </div>

          <div class="item" v-if="item.softwareExpireList && item.softwareExpireList.length > 0">
            <div style="text-align: left">
              <span class="item_title">软著&nbsp;&nbsp;{{item.softwareExpireList && item.softwareExpireList.length ? item.softwareExpireList && item.softwareExpireList.length : 0}}</span>
            </div>
            <!--            <i @click="open(1,index,2, item.applicantName + 2)" :class="[index3 ? 'el-icon-arrow-up' : 'el-icon-arrow-down','icon']"></i>-->
            <el-table
              :header-cell-style="{background: '#D3DBF8'}"
              class="table"
              :row-key="getRowKeys"
              :key="item.applicantName + 2"
              :ref="item.applicantName + 2"
              :data="item.softwareExpireList && item.softwareExpireList"
              tooltip-effect="dark"
              style="width: 660px"
              height="220"
              @selection-change="(val) => handleSelectionChange(val,0,2)">
              <el-table-column
                :reserve-selection="true"
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column
                label="证书编号"
              >
                <template slot-scope="scope">{{ scope.row.softwareNumber }}</template>
              </el-table-column>
              <el-table-column
                prop="softwareName"
                label="软著名称"
              >
              </el-table-column>
              <el-table-column
                prop="softwareType"
                label="证书类型"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                prop="softwareFinishDate"
                label="著作完成时间"
                show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </div>

          <div class="item" v-if="item.certificateExpireList && item.certificateExpireList.length > 0">
            <div style="text-align: left">
              <span class="item_title">资质&nbsp;&nbsp;{{item.certificateExpireList && item.certificateExpireList.length ? item.certificateExpireList && item.certificateExpireList.length : 0}}</span>
            </div>
            <!--            <i @click="open(1,index,3, item.applicantName + 3)" :class="[index4 ? 'el-icon-arrow-up' : 'el-icon-arrow-down','icon']"></i>-->
            <el-table
              :header-cell-style="{background: '#D3DBF8'}"
              class="table"
              :row-key="getRowKeys"
              :key="item.applicantName + 3"
              :ref="item.applicantName + 3"
              :data="item.certificateExpireList && item.certificateExpireList"
              tooltip-effect="dark"
              style="width: 660px"
              height="220"
              @selection-change="(val) => handleSelectionChange(val,0,3)">
              <el-table-column
                :reserve-selection="true"
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column
                label="证书编号"
              >
                <template slot-scope="scope">{{ scope.row.certificateNumber }}</template>
              </el-table-column>
              <el-table-column
                prop="certificateType"
                label="资质类型"
              >
              </el-table-column>
              <el-table-column
                prop="certificateStatus"
                label="状态"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                prop="certificateIssueDate"
                label="发证日期"
                show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </div>

        </div>
        <div style="display: flex;justify-content: center">
          <el-button style="margin-right: 20px" class="save"  @click="save(0)">全部不托管</el-button>
          <el-button  class="save"  @click="save(1)">{{flag ? '托管' :'全部托管'}}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {mapMutations, mapState} from "vuex";
  import store from "../../store";

  export default {
    name: "index",
    data() {
      return {
        value: 0,
        applicantArr: [],
        item: {},
        maturityData: [],
        summaryData: [],
        visible: false,
        applicant: [],
        serviceList: [],
        noticeBool: false,
        dotBool: false,
        isCollapse: false,
        maturityDialogTableVisible: false
      }
    },
    computed: {
      ...mapState(["me", "noticeList", "headerBgBool"]),
      flag(){
        let a = false
        for (let i = 0; i < this.maturityData.length; i++) {
          if(this.maturityData[i].trademarkSaveIdSet.length > 0 || this.maturityData[i].patentSaveIdSet.length > 0 || this.maturityData[i].softwareSaveNumberSet.length > 0 || this.maturityData[i].certificateSaveNumberSet.length > 0){
            a = true;
            break;
          }
        }
        return a
      }
    },
    mounted() {
      this.getServiceList()
      this.editeApplicantName()
      this.getMessageList()
      this.getExpireSummary()
    },
    methods: {
      ...mapMutations(["setMe", "setNoticeList"]),
      queryDataUrl(data) {
        if (data && data.includes("data:image")) return data;
        else if (data && !data.includes("http")) return "https://tmimage.lanternfish.cn" + data;
        else return "";
      },
      changeValue(e){
        this.item = this.summaryData[e]
      },
      getRowKeys(row) {
        return row.platformTmId
      },
      //获取托管到期资质数据
      getExpireSummary(){
        this.$axios.post("/custody/home/custody/expire/summary",{
          "custodyTypeSet":["patent","trademark","software","certificate"]
        }).then(({data}) => {
          if(data.code == 0 && (data.data.length > 0)) {
            this.summaryData = data.data
            this.maturityData = data.data.map(item => {
              item = {
                applicantName: item.applicantName,
                custodyTypeSet: ["patent","trademark","software","certificate"],
                //商标继续托管的id集合
                trademarkSaveIdSet: [],
                //商标移除托管的id集合
                trademarkRemoveIdSet: item.trademarkRenewalExpireList ? item.trademarkRenewalExpireList.map(item => item.trademarkRenewalId) : [],
                //专利继续托管的id集合
                patentSaveIdSet: [],
                //专利移除托管的id集合
                patentRemoveIdSet: item.patentExpireList ? item.patentExpireList.map(item => item.id) : [],
                //软著继续托管的编号集合
                softwareSaveNumberSet: [],
                //软著移除续托管的编号集合
                softwareRemoveNumberSet: item.softwareExpireList ? item.softwareExpireList.map(item => item.softwareNumber) : [],
                //资质继续托管的编号集合
                certificateSaveNumberSet: [],
                //资质移除续托管的编号集合
                certificateRemoveNumberSet: item.certificateExpireList ? item.certificateExpireList.map(item => item.certificateNumber) : []
              }
              return item;
            })
            this.item = this.summaryData[this.value]
            this.maturityDialogTableVisible = true
          }
        })
      },
      //多选的方法
      handleSelectionChange(val, item1, item2) {
        item1 = this.value
        let ids = []
        switch (item2) {
          case 0 :
            ids = val.map(item => item.id)
            this.maturityData[item1].patentSaveIdSet = ids;
            this.maturityData[item1].patentRemoveIdSet = this.maturityData[item1].patentRemoveIdSet.filter(item => !ids.includes(item));
            break;
          case 1 :
            ids = val.map(item => item.trademarkRenewalId)
            this.maturityData[item1].trademarkSaveIdSet = ids;
            this.maturityData[item1].trademarkRemoveIdSet = this.maturityData[item1].trademarkRemoveIdSet.filter(item => !ids.includes(item));
            break;
          case 2 :
            ids = val.map(item => item.softwareNumber)
            this.maturityData[item1].softwareSaveNumberSet = ids;
            this.maturityData[item1].softwareRemoveNumberSet = this.maturityData[item1].softwareRemoveNumberSet.filter(item => !ids.includes(item));
            break;
          case 3 :
            ids = val.map(item => item.certificateNumber)
            this.maturityData[item1].certificateSaveNumberSet = ids;
            this.maturityData[item1].certificateRemoveNumberSet = this.maturityData[item1].certificateRemoveNumberSet.filter(item => !ids.includes(item));
            break;
        }
      },
      save(val) {
        if(val == 1 && !this.flag)  {
          this.maturityData.map(item => {
            //商标继续托管的id集合
            item.trademarkSaveIdSet = item.trademarkRemoveIdSet
            //商标移除托管的id集合
            item.trademarkRemoveIdSet = []
            //专利继续托管的id集合
            item.patentSaveIdSet = item.patentRemoveIdSet
            //专利移除托管的id集合
            item.patentRemoveIdSet = []
            //软著继续托管的编号集合
            item.softwareSaveNumberSet = item.softwareRemoveNumberSet
              //软著移除续托管的编号集合
            item.softwareRemoveNumberSet = []
              //资质继续托管的编号集合
            item.certificateSaveNumberSet = item.certificateRemoveNumberSet
              //资质移除续托管的编号集合
            item.certificateRemoveNumberSet = []
          })
        }
        store.commit("loadingBool", true);
        this.$axios
          .post("/custody/home/custody/expire/save/delete", this.maturityData).then(({data}) => {
          if (data.code == 0) {
            setTimeout(() => {
              store.commit("loadingBool", false);
              this.maturityDialogTableVisible = false
              this.$message({
                message: data.data,
                type: 'success'
              });
            },3000)
          } else {
            store.commit("loadingBool", false);
            this.$message.error(data.data);
          }
        }).catch(err => {
          store.commit("loadingBool", false);
          this.$message.error(err.data.msg);
        })
      },
      jump_(){
        this.$router.push({
          path: '/services'
        })
      },
      getMessageList(){
        this.$axios.get(
          '/custody/applicant/list').then(({data}) => {
        })
      },
      close(){
        this.visible = false
      },
      getName(){
        let path = this.$route.path;
        let name = ''
        switch (path) {
          case '/hostingPlatforms/home': name = '首页'; break;
          case '/hostingPlatforms/annualFee': name = '年费管理'; break;
          case '/hostingPlatforms/myAnnualFee': name = '年费订单'; break;
          case '/hostingPlatforms/annualFee/bs': name = 'BS流程'; break;
          case '/hostingPlatforms/trademark': name = '商标监控'; break;
          case '/hostingPlatforms/myRenewal': name = '商标订单'; break;
          case '/hostingPlatforms/trademark/bs': name = 'BS流程'; break;
          case '/hostingPlatforms/softness': name = '软著管理'; break;
          case '/hostingPlatforms/qualifications': name = '资质管理'; break;
          case '/hostingPlatforms/institution': name = '寻找机构'; break;
          case '/hostingPlatforms/platformPay': name = "订单信息"; break;
          case '/hostingPlatforms/myAnnualFeeOrder': name = "订单详情"; break;
          case '/hostingPlatforms/myRenewalOrder': name = "订单详情"; break;

        }
        return name
      },
      addApplicant() {
        let vipType = this.me.vipType
        switch (vipType) {
          case 'COMMON':
            this.$confirm('暂无托管服务，去购买托管会员', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              // 假设你有一个名为 'about' 的路由
              const routeData = this.$router.resolve({ name: 'services' });
              window.open(routeData.href, '_blank');
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消购买'
              });
            });
            break;
          case 'VIP_A':
            if (this.applicant.length == 1) {
              this.$confirm('您当前的会员等级仅支持托管1个申请人', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                // 假设你有一个名为 'about' 的路由
                const routeData = this.$router.resolve({ name: 'services' });
                window.open(routeData.href, '_blank');
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消购买'
                });
              });
            } else {
              this.$router.push({
                path: "/hostingPlatform"
              })
            }
            break;
          case 'VIP_B':
            if (this.applicant.length == 1) {
              this.$confirm('您当前的会员等级仅支持托管1个申请人', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                // 假设你有一个名为 'about' 的路由
                const routeData = this.$router.resolve({ name: 'services' });
                window.open(routeData.href, '_blank');
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消购买'
                });
              });
            } else {
              this.$router.push({
                path: "/hostingPlatform"
              })
            }
            break;
          case 'VIP_C':
            if (this.applicant.length == 3) {
              this.$confirm('您当前的会员等级仅支持托管3个申请人', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                // 假设你有一个名为 'about' 的路由
                const routeData = this.$router.resolve({ name: 'services' });
                window.open(routeData.href, '_blank');
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消购买'
                });
              });
            } else {
              this.$router.push({
                path: "/hostingPlatform"
              })
            }
            break;
          case 'VIP_D':
            if (this.applicant.length == 5) {
              this.$confirm('您当前的会员等级仅支持托管5个申请人', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                // 假设你有一个名为 'about' 的路由
                const routeData = this.$router.resolve({ name: 'services' });
                window.open(routeData.href, '_blank');
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消购买'
                });
              });
            } else {
              this.$router.push({
                path: "/hostingPlatform"
              })
            }
            break;
          default:
            this.$confirm('暂无托管服务，去购买托管会员', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              // 假设你有一个名为 'about' 的路由
              const routeData = this.$router.resolve({ name: 'services' });
              window.open(routeData.href, '_blank');
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消购买'
              });
            });
            break;
        }
      },

      //修改托管平台
      goApplicant(item){
        this.$router.push({
          path: "/hostingPlatform?id=" + item.applicantId
        })
      },
      editeApplicantName() {
        this.$axios.get(
          '/custody/applicant/list').then(({data}) => {
          if(data.code == 0){
            this.applicant = data.data.filter(item => item.storeFlag)
            this.applicant = this.applicant.map((item, index) => {
              return {
                "applicantId": item.applicantId,
                "value": index,
                "text": item.applicantName
              }
            })
            this.applicantArr = data.data
          }
        })
      },
      goPay(){
        this.$router.push({
          path: "/services"
        });
      },
      getServiceList() {
        this.$axios.get("/product/servicemanage/getCustodyServiceList").then(({data}) => {
          if (data.code == 0) {
            this.serviceList = data.data.map(item => {
              item.endTime = item.endTime.substring(0, 10)
              return item
            })
          }
        })
      },
      //立即购买
      goPays(){
        // 打开新窗口
        window.open(this.$router.resolve({ path: '/services' }).href, '_blank');
      },
      // 更新蓝灯鱼用户通知消息状态
      async updateMessageAjax () {
        let params = [];
        for (var i = 0; i < this.unreadList.length; i++) {
          params.push(this.unreadList[i].id);
        }
        let data = await this.$axios.put(
          "/lantern/messageLan/updateMessage",
          {
            longList: JSON.stringify(params)
          },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8"
            }
          }
        );
        if (data.data.code == 0) {
          this.dotBool = false;
        }
      },
      // 更新通知消息
      noticeEvent () {
        this.unreadList = [];
        for (var i = 0; i < this.noticeList.length; i++) {
          if (this.noticeList[i].isLook == 0) {
            this.dotBool = true;
            this.unreadList.push(this.noticeList[i]);
          }
        }
      },
      mouseleaveEvent () {
        this.noticeBool = false;
        if (this.dotBool) {
          this.updateMessageAjax();
        }
      },
      //手机号正则
      getIphone(val){
        if(val.indexOf("86") == 0){
          var matchReg = /^86/;
          return val.replace(matchReg, "");
        }else if(val.indexOf("852") == 0){
          var matchReg = /^852/;
          return val.replace(matchReg, "");
        }else if(val.indexOf("853") == 0){
          var matchReg = /^853/;
          return val.replace(matchReg, "");
        }else {
          return val
        }
      },
      menu(){
        this.isCollapse = !this.isCollapse
      },
      // 个人中心、退出登录事件
      async handleCommand (item) {
        this.visible = !this.visible
        return;
      }
    }
  }
</script>

<style lang="less">
  .maturityDialogTable {
    width: 1200px;

    margin: 0 auto;
    background: #FFFFFF;
    overflow: hidden;
    .myContent{
      position: relative;
      width: 1150px;
      margin: 50px 28px;
      text-align: center;

      .myTitle{
        position: absolute;
        display: inline-block;
        top: 0;
        left: 533px;
        font-size: 22px;
        color: #333333;
      }

      .topLeftContent{
        text-align: left;
        background-size: 100% 100%;
        background-image: url("../../assets/images/hostingPLatform/leftTopBG.png");
        height: 260px;
        padding: 80px 0 0 86px;
      }
      .topRightContent{
        text-align: left;
        height: 260px;
        padding: 83px 0 0 9px;
      }
      .bottomLeftContent{
        text-align: left;
        height: 260px;
        padding: 8px 0 0 95px;
      }
      .bottomRightContent{
        text-align: left;
        background-size: 100% 100%;
        background-image: url("../../assets/images/hostingPLatform/rightBottomBG.png");
        height: 260px;
        padding: 8px 0 0 8px;
      }
      .myBorderBox1{
        position: relative;
        height: 172px;
        width: 425px;
        border-radius: 8px;
        h3{
          margin-top: 40px;
          margin-left: 100px;
        }
        p{
          margin-top: 20px;
          margin-left: 100px;
        }
      }
      .myBorderBox2{
        position: relative;
        display: inline-block;
        height: 170px;
        width: 458px;
        box-shadow: 0 0 6px 2px rgba(12,26,76,0.12);
        border-radius: 8px;
        h3{
          margin-top: 40px;
          margin-left: 100px;
        }
        p{
          margin-top: 20px;
          margin-left: 100px;
          margin-right: 30px;
        }
      }
      .myBorderBox3{
        position: relative;
        display: inline-block;
        height: 155px;
        width: 470px;
        box-shadow: 0 0 6px 2px rgba(12,26,76,0.12);
        border-radius: 8px;
        h3{
          margin-top: 30px;
          margin-left: 90px;
        }
        p{
          margin-top: 20px;
          margin-left: 90px;
          margin-right: 30px;
        }
      }
      .myBorderBox4{
        position: relative;
        height: 156px;
        width: 417px;
        border-radius: 8px;
        h3{
          margin-top: 30px;
          margin-left: 100px;
        }
        p{
          margin-top: 20px;
          margin-left: 100px;
        }
      }
      .advantagesContentImage1{
        position: absolute;
        top: 10px;
        left: 20px;
        height: 70px;
        width: 67px;
      }
      .advantagesContentImage2{
        position: absolute;
        top: 45px;
        left: 15px;
        height: 75px;
        width: 67px;
      }
      .advantagesContentImage3{
        position: absolute;
        top: 45px;
        left: 15px;
        height: 75px;
        width: 67px;
      }
      .advantagesContentImage4{
        position: absolute;
        top: 10px;
        left: 15px;
        height: 77px;
        width: 71px;
      }
      h3{
        color: #555555;
        font-size: 16px;
      }
      p{
        color: #7D7D7D;
        font-size: 14px;
      }
    }

    .box-card {
      /*text-align: center;*/
      /*width: 1000px;*/
      padding-bottom: 15px;
      box-sizing: border-box;
      height: 370px;
      background-image: linear-gradient(45deg, #D1D9F1, transparent 70%);
      box-shadow: none;
      border: none;

      .clearfix {
        display: flex;
        flex-direction: column;
        padding: 50px 250px;
        box-sizing: border-box;
        position: relative;
        .clearfix_header {
          font-size: 22px;
          font-weight: bolder;
          color: #444444;
          line-height: 33px;
          letter-spacing: 2px;
          position: relative;
          padding-left: 12px;
          &::before {
            top: 5px;
            left: 0;
            position: absolute;
            content: '';
            width: 5px;
            height: 24px;
            background: #465CFC;
          }
        }
        .clearfix_content {
          width: 680px;
          text-align: right;
          margin-top: 40px;
          /deep/ .el-select {
            .el-icon-circle-close {
              font-size: 24px;
            }
            .el-input__inner {
              font-size: 18px;
            }
            input {
              width: 680px;
              height: 50px;
              background: #FFFFFF;
              border-radius: 12px;
              border: 1px solid #B4C5E5;
            }
          }
          .btn {
            margin-top: 59px;
            width: 115px;
            height: 45px;
            background: #2E54D9;
            border-radius: 8px;
            font-size: 18px;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }
    }

    .home_top {
      height: 90px;
    }

    .save {
      cursor: pointer;
      width: 98px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #2E54D9;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
    }

    .summaryItem {
      width: 700px;

      .applicantName {
        text-align: left;
        padding-left: 62px;
        font-size: 22px;
        font-weight: 500;
        color: #2E54D9;
        letter-spacing: 1px;
        margin-bottom: 35px;
      }

      .item {
        width: 730px;
        min-height: 294px;
        padding-bottom: 20px;
        margin-bottom: 36px;
        padding-top: 17px;
        position: relative;
        background: #EFF2FD;
        overflow: hidden;
        border-radius: 12px;
        border: 1px solid #E1E7F2;

        .table {
          border-radius: 6px 6px 0px 0px;
          margin: 24px 0 10px 40px;

          /deep/ th.el-table__cell {
            background: #D3DBF8 !important;
          }
        }

        .item_title {
          position: relative;
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          padding-left: 14px;
          margin: 0px 0 0 19px;

          &::before {
            position: absolute;
            left: 0;
            top: 5px;
            content: '';
            width: 4px;
            height: 18px;
            background: #465CFC;
          }
        }

        .icon {
          position: absolute;
          font-size: 16px;
          cursor: pointer;
          top: 30px;
          right: 58px;
        }
      }
    }
    .summaryData {
      max-height: 600px;
      overflow-y: auto;
      .summaryData_title {
        /*margin: 0px 33px;*/
        display: flex;

        img {
          width: 20px;
          height: auto;
          margin-right: 9px;
        }

        span {
          font-size: 22px;
          font-weight: 500;
          color: #444444;
          letter-spacing: 1px;
        }
      }
    }

  }
  .el-menu--vertical {
    .el-menu-item-group {
      background: white!important;
      ul {
        li {
          color: #2D3748;
          &:hover {
            color: #2D3748!important;
          }
        }
      }
    }
  }
  .hostingPlatformpopper {
    padding: 15px 25px 0px 25px;
    box-sizing: border-box;
    margin-right: 7px;
    top: 72px!important;
    height: calc(100% - 150px); /* 减去固定的像素值 */
    position: relative;
    .myTitle {
      display: flex;
      justify-content: space-between;
      position: relative;
      padding-bottom: 12px;
      box-sizing: border-box;
      margin-bottom: 23px;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 10px;
        width: 302px;
        height: 1px;
        background: linear-gradient( 90deg, rgba(224,225,226,0) 0%, #E0E1E2 0%, rgba(224,225,226,0.16) 100%);
        border-radius: 0px 0px 0px 0px;
      }
      span{
        &:nth-of-type(1) {
          font-weight: 600;
          font-size: 18px;
          color: #2D3748;
        }
        img {
          cursor: pointer;
          width: 26px;
          height: auto;
        }
      }
    }
    .membershipManagement {
      display: flex;
      justify-content: space-between;
      margin-bottom: 19px;
      box-sizing: border-box;
      div{
        &:nth-of-type(1) {
          padding-left: 11px;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
          position: relative;
          &:before {
            position: absolute;
            content: '';
            width: 3px;
            height: 16px;
            background: #2264E5;
            top: 1px;
            left: 0;
          }
        }
        &:nth-of-type(2) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 58px;
          height: 24px;
          background: linear-gradient( 180deg, #FBE4AC 0%, #FBEBCE 100%);
          border-radius: 12px;
          font-weight: 500;
          font-size: 12px;
          color: #583F13;
        }
      }
    }
    .service {
      margin-bottom: 32px;
      .el-carousel {
        box-sizing: border-box;
        .task-center {
          width: 291px;
          position: relative;
          .date {
            position: absolute;
            right: 20px;
            bottom: 21px;
            font-weight: 400;
            font-size: 11px;
            color: #C6C2C2;
          }
          img {
            width: 291px;
            height: auto;
          }
        }
      }
    }
    .applicantManagement {
      .applicantManagement_title{
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        div {
          &:nth-of-type(1) {
            padding-left: 11px;
            font-weight: 600;
            font-size: 14px;
            color: #000000;
            position: relative;
            &:before {
              position: absolute;
              content: '';
              width: 3px;
              height: 16px;
              background: #2264E5;
              top: 1px;
              left: 0;
            }
          }
          &:nth-of-type(2) {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 14px;
            color: #30396C;
            img {
              margin-right: 3px;
            }
          }
        }
      }
      .applicantManagement_content {
        li {
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 8px 8px 8px 8px;
          padding: 0 24px 0 13px;
          box-sizing: border-box;
          font-weight: 500;
          font-size: 14px;
          color: #2D3748;
          &:hover {
            background: #E4EDFF;
          }
          img {
            width: 24px;
            height: auto;
            cursor: pointer;
          }
          &:nth-of-type(1) {
            margin-top: 22px;
          }
        }
      }
    }
    .contactUs {
      width: 313px;
      height: 236px;
      background: #E4EDFF;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      bottom: 30px;
      ul {
        width: 234px;
        display: flex;
        flex-wrap: wrap;
        margin: 21px 0 18px 0;
        li {
          font-weight: 600;
          font-size: 14px;
          color: #30396C;
          &:nth-of-type(1) {
            margin-right: 5px;
          }
          &:nth-of-type(3) {
            margin-top: 8px;
          }
        }
      }
      img {
        width: 234px;
        height: auto;
      }
    }
  }
</style>

<style lang="less" scoped>
  .hostingPlatforms{
    height: 100vh;
    overflow: hidden;
    background: #F3F4F6;
    padding: 15px 28px 0 28px ;
    box-sizing: border-box;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 58px;
      .left {
        display: flex;
        align-items: center;
        width: 230px;
        &.active {
          width: 100px;
        }
        justify-content: space-between;
        position: relative;
        &:before {
          width: 241px;
          height: 1px;
          content: '';
          bottom: -15px;
          position: absolute;
          background: linear-gradient( 90deg, rgba(224,225,226,0) 0%, #E0E1E2 50%, rgba(224,225,226,0.16) 100%);
        }
        .logo {
          width: 125px;
          height: auto;
        }
        .logos {
          display: flex;
          width: 80px;
          justify-content: center;
          img {
            cursor: pointer;
            width: 45px;
            height: auto;
          }
        }
        .menu {
          width: 40px;
          height: 40px;
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: center;
          background: #FFFFFF;
          box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.08);
          border-radius: 10px;
          img {
            width: 24px;
            height: auto;
          }
        }
      }
      .right {
        display: flex;
        align-items: center;
        width: calc(100% - 266px);
        &.active {
          width: calc(100% - 100px);
        }
        height: 58px;
        padding: 0 41px 0 15px;
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.08);
        border-radius: 12px 12px 12px 12px;
        justify-content: space-between;
        .el-breadcrumb {
          /deep/.el-breadcrumb__item {
            span {
              color: #999999!important;
              font-size: 14px;
            }
            &:nth-of-type(3) {
              span {
                color: #000000!important;
              }
            }
          }
        }
        .home_logo{
          width: 14px;
          height: auto;
          margin-right: 8px;
        }
        ul {
          margin-left: 50px;
          /deep/.en_cn_Switch{
            .el-input__inner{
              height: 30px;
              line-height: 30px;
            }
          }
          li {
            display: inline-block;
            cursor: pointer;
            color: #3d3d3d;
            &.en_btn {
              margin-right: 40px;
            }
            &.login_btn {
              font-size: 16px;
              /*width: 88px;*/
              height: 30px;
              padding: 0 10px;
              box-sizing: border-box;
              line-height: 30px;
              text-align: center;
              border: 1px solid #ffffff;
              border-radius: 8px;
              color: #fff;

              &:hover {
                box-shadow: @boxShadow;
              }
            }
            &.notice {
              color: #fff;
              font-size: 22px;
              margin-left: 20px;
              position: relative;
              margin-top: -4px;
              .el-icon-bell {
                &:hover {
                  color: @blue;
                }
              }
              .notice_po {
                width: 30px;
                height: 27px;
                position: absolute;
                left: 50%;
                margin-left: -15px;
                .notice_con {
                  position: absolute;
                  top: 24px;
                  right: -210px;
                  z-index: 9;
                  width: 510px;
                  max-height: 490px;
                  overflow: auto;
                  background-color: #ffffff;
                  box-shadow: 0px 6px 40px 0px rgba(110, 119, 123, 0.14);
                  border: solid 1px #ededed;
                  padding: 32px 0 50px;
                  box-sizing: border-box;
                  box-sizing: border-box;
                  li {
                    &:last-child {
                      margin-left: 0;
                    }
                    &:hover {
                      cursor: pointer;
                      background: #f8f8f8;
                    }
                    p {
                      width: 440px;
                      margin: 0 34px;
                      padding: 20px 0;
                      box-sizing: border-box;
                      font-size: 14px;
                      border-bottom: 1px solid#e7e6e6;
                      span {
                        display: block;
                        color: #4c4c4c;
                        margin-bottom: 9px;
                        line-height: 22px;
                        b {
                          color: @blue;
                          margin-right: 10px;
                        }
                      }
                      i {
                        color: #888888;
                      }
                    }
                    &.no_notice {
                      width: 100%;
                      text-align: center;
                      font-size: 14px;
                      color: #888888;
                      &:hover {
                        background: #fff;
                        cursor: default;
                      }
                    }
                  }
                  &.noDefault {
                    cursor: default;
                  }
                }
              }
              .en_cn_Switch{

              }
            }
          }
        }
      }
    }
    .el-container{
      padding: 0px;
      box-sizing: border-box;
      margin: 0px;
      height: 100%!important;
      .el-main {
        padding: 21px 0 0 36px;
        box-sizing: border-box;
      }

      /deep/.el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 230px;
        min-height: 400px;
      }
      .el-menu-vertical-demo{
        border-right: none;
        /deep/.el-menu-item-group__title{
          display: none;
        }
        /deep/.el-submenu__title:hover{
          background: #ffffff!important;
          border-radius: 10px;
        }
        /deep/.el-submenu__title {
          border-radius: 10px!important;
        }
        .el-menu-item-group {
          /deep/ul {
            background: #F3F4F6!important;
          }
          /deep/.el-menu-item {
            background: #F3F4F6!important;
            &.is-active {
              background: #F3F4F6!important;
              color: #1C59C3!important;
            }
          }
        }

        li{
          &.el-menu-item.is-active {
            background: #ffffff!important;
            border-radius: 10px;
            font-weight: 600;
          }
          &.el-submenu.is-active.is-opened {
            /deep/.el-submenu__title {
              color: rgb(45, 55, 72)!important;
              font-weight: 600;
            }
          }
          &.is-active{
            /deep/.el-submenu__title {
              background: #ffffff!important;
              border-radius: 10px;
              font-weight: 600;
              overflow: hidden;
            }
          }
          &:hover{
            &.el-menu-item {
              background: #ffffff!important;
              border-radius: 10px;
            }
            /deep/.el-submenu__title {
              background: #ffffff!important;
              border-radius: 10px;
            }
          }
        }
      }
    }
  }


  /deep/.el-aside {
    height: 880px!important;
    position: relative;
    width: auto!important;
    margin-top: 23px;
    .content {
      cursor: pointer;
      position: absolute;
      bottom: 75px;
    }
    .el-menu--collapse {
      .el-tooltip {
        padding-left: 10px!important;
      }
      .el-submenu__title {
        padding-left: 10px!important;
      }
      li{
        &.el-menu-item.is-active {
          background: #ffffff!important;
          border-radius: 10px;
        }
        &.is-active{
          /deep/.el-submenu__title {
            background: #ffffff!important;
            border-radius: 10px;
            overflow: hidden;
          }
        }
        &:hover{
          &.el-menu-item {
            background: #ffffff!important;
            border-radius: 10px;
          }
          /deep/.el-submenu__title {
            background: #ffffff!important;
            border-radius: 10px;
          }
        }
      }
    }
  }

</style>
